import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clause-category-terms',
  templateUrl: './clause-category-terms.component.html',
  styleUrls: ['./clause-category-terms.component.scss']
})
export class ClauseCategoryTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
