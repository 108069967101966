import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] =
    [
        {
            id: '',
            title: '',
            translate: 'NAV.APPLICATIONS',
            type: 'group',
            icon: 'apps',
            children: []
        }
    ];
