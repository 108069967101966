import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models';
import { config } from '../config/config';
import { Options } from 'selenium-webdriver';
import { Crypto } from './crypto.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;

    public currentUser: Observable<User>;

    baseUrl = config.baseUrl;

    constructor(private http: HttpClient, private crptoJS: Crypto, private router:Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public getcurrentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email, password) {

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8')


        return this.http.post<any>(`${this.baseUrl}/superadminlogin`,
            { info: this.crptoJS.encryptData({ email, password }) },
            { headers: headers })
            .pipe(
                map(user => {
                    user.data = this.crptoJS.decryptData(user.data);
                    user = user;
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return user;
                })
            );
    }

    register(username, email, password, otp, firstname, lastname, mobileno) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this.http.post<any>(`${this.baseUrl}/superadminregister`, { username, email, password, otp, firstname, lastname, mobileno }, {
            headers: headers
        })
            .pipe(
                map(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    return user;
                })
            );
    }

    forgotPassword(email) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this.http.post<any>(`${this.baseUrl}/forgotpassword`,
            { info: this.crptoJS.encryptData({ email }) },
            { headers: headers })
            .pipe(
                map(user => {
                    return user;
                })
            );
    }

    userChangePassword(resetToken, resetpassword) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');

        return this.http.post<any>(`${this.baseUrl}/adminresetpassword`,
            { info: this.crptoJS.encryptData({ resetToken, resetpassword }) },
            { headers: headers })
            .pipe(
                map(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    return user;

                })
            );
    }

    changePassword(oldpassword, newpassword, id) {

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8').set('authorization', 'bearer ' + localStorage['userToken']);

        return this.http.post<any>(`${this.baseUrl}/changepassword`,
            { info: this.crptoJS.encryptData({ oldpassword, newpassword, id }) },
            { headers: headers })
            .pipe(
                map(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    return user;
                })
            );
    }

    logout() {
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.router.navigate(['/pages/auth/login']);
    }

}