import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../_models';
import { config } from '../config/config';
import { Router } from '@angular/router';
import { Crypto } from './crypto.service';

@Injectable({ providedIn: 'root' })

export class UserService {

    userToken: any;

    public headers;

    constructor(private http: HttpClient, private router: Router, private crptoJS: Crypto) {

        const token = JSON.parse(localStorage.getItem('currentUser')).token;

        if (token == null) {
            this.router.navigate(['/pages/auth/login'])
        }

        this.setHeader();
    }

    setHeader() {
        this.headers = new HttpHeaders().set('authorization', 'bearer ' + localStorage.getItem('userToken'));
        console.log(this.headers);
    }

    addprofile(id, req) {
        return this.http.post(`${config.baseUrl}/image_uplaod/` + id,
            req, { headers: this.headers });
    }

    getUserList(id) {
        return this.http.get(`${config.baseUrl}/userList/` + id,
            { headers: this.headers });
    }

    getbrokercount() {
        return this.http.get(`${config.baseUrl}/getbrokercount`,
            { headers: this.headers });
    }

    getchartercount() {
        return this.http.get(`${config.baseUrl}/getchartercount`,
            { headers: this.headers });
    }

    getownercount() {
        return this.http.get(`${config.baseUrl}/getownercount`,
            { headers: this.headers });
    }

    getsubadmincount() {
        return this.http.get(`${config.baseUrl}/getsubadmincount`,
            { headers: this.headers });
    }

    userFatchListSoftDelete() {
        return this.http.get(`${config.baseUrl}/userFatchListSoftDelete`,
            { headers: this.headers });
    }

    getplateformList(req) {
        return this.http.post(`${config.baseUrl}/platformAdminlist`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }

    platformAdminCreate(req) {
        return this.http.post(`${config.baseUrl}/platformAdminCreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }

    plateformStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/plateformStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }

    getCompannewyList() {
        return this.http.get(`${config.baseUrl}/companylist`,
            { headers: this.headers });
    }

    getCompanyListsoftDelete() {
        return this.http.get(`${config.baseUrl}/companyadminListSoftDelete`,
            { headers: this.headers });
    }

    getChartererList() {
        return this.http.get(`${config.baseUrl}/charterlist`,
            { headers: this.headers });
    }

    getChartererListforcompany(req) {
        return this.http.post(`${config.baseUrl}/charterlistforcomapny`,
        { info: this.crptoJS.encryptData(req) },
        { headers: this.headers });
    }

    getOwnerListforcompany(req) {
        return this.http.post(`${config.baseUrl}/ownerlistforcomapny`,
        { info: this.crptoJS.encryptData(req) },
        { headers: this.headers });
    }

    getbrokererListforcompany(req) {
        return this.http.post(`${config.baseUrl}/AllBrokerlistforcompany`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getbrokererList_new() {
        return this.http.get(`${config.baseUrl}/AllBrokerlistincludecompany`,
            { headers: this.headers });
    }

    getbrokererList() {
        return this.http.get(`${config.baseUrl}/AllBrokerlist`,
            { headers: this.headers });
    }

    addCompanyList(req) {
        return this.http.post(`${config.baseUrl}/companycreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    newsStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/newsStatusUpdate`, req);
    }

    updateCompanyList(req) {
        return this.http.post(`${config.baseUrl}/companyupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    companyStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/companyStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    subAdminStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/subAdminStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateCompanyadminList(req) {
        return this.http.post(`${config.baseUrl}/companyadminupdate`, req);
    }
    updateUserManagement(req) {
        return this.http.post(`${config.baseUrl}/userupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    subadminUserUpdate(req) {
        return this.http.post(`${config.baseUrl}/subadminUserUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateUserImage(id, req) {
        return this.http.post(`${config.baseUrl}/image_uplaod/` + id, req);
    }

    getCompanyadminList() {
        return this.http.get(`${config.baseUrl}/companyadminlist`,
            { headers: this.headers });
    }

    updateCharterer(req) {
        return this.http.post(`${config.baseUrl}/charterupdate`, req);
    }
    getChartererDetail(req) {
        return this.http.post(`${config.baseUrl}/charterdetails`, req);
    }

    getbrokererDetail(req) {
        return this.http.post(`${config.baseUrl}/BrokerDetails`, req);
    }

    getvesselDetail(req) {
        return this.http.post(`${config.baseUrl}/vesseldetails`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    VesselList() {
        return this.http.get(`${config.baseUrl}/vessellist`,
            { headers: this.headers });
    }

    getactivitylist() {
        return this.http.get(`${config.baseUrl}/vesselactivity`,
            { headers: this.headers });
    }

    AddVessel(req) {
        return this.http.post(`${config.baseUrl}/vesseladd`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getstateList() {
        return this.http.get(`${config.baseUrl}/vesselbase`,
            { headers: this.headers });
    }

    getbuilderList() {
        return this.http.get(`${config.baseUrl}/vesselbuilder`,
            { headers: this.headers });
    }

    getflagList() {
        return this.http.get(`${config.baseUrl}/vesselflag`,
            { headers: this.headers });
    }

    getstatusList() {
        return this.http.get(`${config.baseUrl}/vesselstatus`,
            { headers: this.headers });
    }

    getcommmentList() {
        return this.http.get(`${config.baseUrl}/vesselcomment`,
            { headers: this.headers });
    }

    getenginedesignList() {
        return this.http.get(`${config.baseUrl}/auxenginedesignlist`,
            { headers: this.headers });
    }

    getenginetypeList() {
        return this.http.get(`${config.baseUrl}/auxenginetypelist`,
            { headers: this.headers });
    }

    getfueltypeList() {
        return this.http.get(`${config.baseUrl}/fueltypeslist`,
            { headers: this.headers });
    }

    getnationalityList() {
        return this.http.get(`${config.baseUrl}/nationalitylist`,
            { headers: this.headers });
    }

    getvesseltypeeList() {
        return this.http.get(`${config.baseUrl}/shiptypelist`,
            { headers: this.headers });
    }

    getcompanyList() {
        return this.http.get(`${config.baseUrl}/companylist`,
            { headers: this.headers });
    }

    getregistryList() {
        return this.http.get(`${config.baseUrl}/portregistrylist`,
            { headers: this.headers });
    }
    addSubAlert(req) {
        return this.http.post(`${config.baseUrl}/subcategoryAdd`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    deleteVessel(req) {
        return this.http.post(`${config.baseUrl}/vesseldelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    updateVessel(req) {
        return this.http.post(`${config.baseUrl}/vesselupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    addFile(req) {
        return this.http.post(`${config.baseUrl}/fileupload`,
            req, { headers: this.headers });
    }

    roleActiveInactive(req) {
        return this.http.post(`${config.baseUrl}/userroleactive`, req);
    }

    selectList(req) {
        return this.http.post(`${config.baseUrl}/selectrolelist`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    moduleAction(req) {
        return this.http.post(`${config.baseUrl}/moduleactioncreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    PositionList() {
        return this.http.get(`${config.baseUrl}/vesselpositionlist`,
            { headers: this.headers })
    }

    roleListshow() {
        return this.http.get(`${config.baseUrl}/roleAccessmoduleView`,
            { headers: this.headers })
    }

    /* currency managment */
    addCurrency(req) {
        console.log("fronted end call", req)
        return this.http.post(`${config.baseUrl}/addCurrency`,
            req,
            { headers: this.headers });
    }

    getCurrency() {
        return this.http.get(`${config.baseUrl}/getCurrencys`,
            { headers: this.headers })
    }

    updateCurrency(req) {
        return this.http.post(`${config.baseUrl}/updateCurrency`, req, { headers: this.headers });
    }

    allCurrencySetStatus(req) {
        console.log(req);
        return this.http.post(`${config.baseUrl}/allCurrencySetStatus`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getCompanyCurrencys(req) {
        return this.http.post(`${config.baseUrl}/getCompanyCurrencys`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    actionOnCompanyCurrency(req) {
        return this.http.post(`${config.baseUrl}/actionOnCompanyCurrency`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Charter Party Type Routes
    // Charter Party Type Records
    getcharterpartytypeList() {
        return this.http.get(`${config.baseUrl}/charterpartylist`)
    }
    // Charter Party Type Create
    getcharterpartytypeadd(req) {
        return this.http.post(`${config.baseUrl}/charterpartytypecreate`, req);
    }
    // Charter Party Type Update
    getcharterpartytypeupdate(req) {
        return this.http.post(`${config.baseUrl}/charterpartypeupdate`, req);
    }
    // Charter Party Type Remove
    getcharterpartytypedelete(req) {
        return this.http.post(`${config.baseUrl}/charterpartytypedelete`, req);
    }
    // Charter Party Type Status Update
    charterPartyStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/charterPartyStatusUpdate`, req);
    }

    // CP Form Routes
    // CP Form Records
    getFormList() {
        return this.http.get(`${config.baseUrl}/cpFromlist`,
            { headers: this.headers })
    }

    getFormList_new() {
        return this.http.get(`${config.baseUrl}/cpFormlist_new`,
            { headers: this.headers })
    }
    // CP Form Create
    getFormadd(req) {
        return this.http.post(`${config.baseUrl}/cpFromcreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }
    // CP Form Update
    getFormedit(req) {
        return this.http.post(`${config.baseUrl}/cpFromupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }
    // CP Form Remove
    getFormdelete(req) {
        return this.http.post(`${config.baseUrl}/cpFromdelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // CP Form Status Update
    cpFormStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/cpFormStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }

    // Clause Category Routes
    // Clause Category Records
    getclusesCategoryList() {
        return this.http.get(`${config.baseUrl}/clusesCategorylist`)
    }
    // Clause Category Server Side Records
    clauseCategoryServerSideRecords(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryServerSideRecords`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }
    // Clause Category Create
    getclusesCategoryadd(req) {
        return this.http.post(`${config.baseUrl}/clusesCategorycreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Clause Category Update
    getclusesCategoryupdate(req) {
        return this.http.post(`${config.baseUrl}/clusesCategoryupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Clause Category Remove
    getclusesCategorydelete(req) {
        return this.http.post(`${config.baseUrl}/clusesCategorydelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Clause Category Status Update
    clauseCategoryStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // Clause Category Terms Routes
    // Clause Category Terms Records
    getclusesList() {
        return this.http.get(`${config.baseUrl}/cluseslist`,
            { headers: this.headers })
    }
    // Clause Category Terms Create
    getclusesadd(req) {
        return this.http.post(`${config.baseUrl}/clusescreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Clause Category Terms Update
    getclusesupdate(req) {
        return this.http.post(`${config.baseUrl}/clusesupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Clause Category Terms Remove
    getclusesdelete(req) {
        return this.http.post(`${config.baseUrl}/clusesdelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Clause Cateogry Terms Status Update
    clauseTermsStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/clauseTermsStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // Clause Cateogry Terms Records Server Side
    clauseTermsDetailsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/clauseTermsDetailsRecordsServerSide`, req);
    }
    // Clause Cateogry Terms Review Records Server Side
    clauseTermsReviewsRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/clauseTermsReviewsRecordsServerSide`, req);
    }


    // Draw Form Routes
    // Draw Form Records Service
    drawFormRecords() {
        // console.log('here in user services');
        return this.http.get(`${config.baseUrl}/drawFormRecords`)
    }
    drawRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/drawRecordsServerSide`, req)
    }
    // Draw Form Create Service
    DrawFormCreate(req) {
        return this.http.post(`${config.baseUrl}/DrawFormCreate`, req)
    }
    // Draw Form Update Service
    drawFormUpdate(req) {
        return this.http.post(`${config.baseUrl}/drawFormUpdate`, req)
    }
    // Draw Form Remove Service
    drawDataRemove(req) {
        return this.http.post(`${config.baseUrl}/drawDataRemove`, req)
    }

    // CityForm Routes
    // CityForm Records Service
    CityRecords() {
        return this.http.get(`${config.baseUrl}/CityRecords`,
            { headers: this.headers });
    }

    // CityForm Create Service
    CityCreate(req) {
        return this.http.post(`${config.baseUrl}/CityCreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // CityForm Update Service
    CityUpdate(req) {
        return this.http.post(`${config.baseUrl}/CityUpdate`, req)
    }

    // CityForm Remove Service
    CityRemove(req) {
        return this.http.post(`${config.baseUrl}/CityRemove`, req)
    }

    // faq Records Service
    faqlist() {
        return this.http.get(`${config.baseUrl}/faqlist`,
            { headers: this.headers });
    }
    // faq Create Service
    faqcreate(req) {
        return this.http.post(`${config.baseUrl}/faqcreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // faq Update Service
    faqupdate(req) {
        return this.http.post(`${config.baseUrl}/faqupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    faqStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/faqStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // faq Remove Service
    faqdelete(req) {
        return this.http.post(`${config.baseUrl}/faqdelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    faqarchivelist() {
        return this.http.get(`${config.baseUrl}/faqarchivelist`,
            { headers: this.headers });
    }

    faqArchivedelete(req) {
        return this.http.post(`${config.baseUrl}/faqArchivedelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }



    // faq Records Service

    tclist() {
        return this.http.get(`${config.baseUrl}/tclist`,
            { headers: this.headers });
    }
    // faq Create Service

    tccreate(req) {
        return this.http.post(`${config.baseUrl}/tccreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    // tc Update Service

    tcupdate(req) {
        return this.http.post(`${config.baseUrl}/tcupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tcStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/tcStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // tc Remove Service
    tcdelete(req) {
        return this.http.post(`${config.baseUrl}/tcdelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tcarchivelist() {
        return this.http.get(`${config.baseUrl}/tcarchivelist`,
            { headers: this.headers });
    }

    tcarchivedelete(req) {
        return this.http.post(`${config.baseUrl}/tcarchivedelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }


    // userSetting Records Service
    userSettinglist() {
        return this.http.get(`${config.baseUrl}/userSettinglist`,
            { headers: this.headers })
    }

    userSettingarchivelist() {
        return this.http.get(`${config.baseUrl}/userSettingarchivelist`,
            { headers: this.headers })
    }

    // userSetting Create Service
    userSettingcreate(req) {
        return this.http.post(`${config.baseUrl}/userSettingcreate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }

    // userSetting Update Service
    userSettingupdate(req) {
        return this.http.post(`${config.baseUrl}/userSettingupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
    userSettingStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/userSettingStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // userSetting Remove Service
    userSettingdelete(req) {
        return this.http.post(`${config.baseUrl}/userSettingdelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    userSettingArchivedelete(req) {
        return this.http.post(`${config.baseUrl}/userSettingArchivedelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    // subadmin role management
    // CityForm Create Service
    subadminroleCreate(req) {
        return this.http.post(`${config.baseUrl}/subadminroleCreate`, req)
    }
    // CityForm Update Service
    subadminroleUpdate(req) {
        return this.http.post(`${config.baseUrl}/subadminroleupdate`, req)
    }
    // CityForm Remove Service
    subadminroleget(req) {
        return this.http.get(`${config.baseUrl}/subadminroleread`, req)
    }


    DrawStatusData(req) {
        return this.http.post(`${config.baseUrl}/DrawStatusData`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradeStatusData(req) {
        return this.http.post(`${config.baseUrl}/tradeStatusData`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    TradingFormRecordsServerSide(req) {
        return this.http.post(`${config.baseUrl}/TradingFormRecordsServerSide`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    timerList() {
        return this.http.get(`${config.baseUrl}/tradetimerlist`,
            { headers: this.headers })
    }

    AllUsersRecords(req) {
        return this.http.post(`${config.baseUrl}/AllUsersRecords`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    newUsersRecords(req) {
        return this.http.post(`${config.baseUrl}/newUsersRecords`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    timerUpdate(req) {
        return this.http.post(`${config.baseUrl}/tradetimerupdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getCalligraphy() {
        return this.http.get(`${config.baseUrl}/getCalligraphy`,
            { headers: this.headers })
    }

    saveCalligraphy(req) {
        return this.http.post(`${config.baseUrl}/saveCalligraphy`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    alertCategoriesList(req) {
        return this.http.post(`${config.baseUrl}/alertCategoriesList`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseCategoryAndClauseTermsRecordsAccordingToCpForm(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryAndClauseTermsRecordsAccordingToCpForm`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }


    claueseCategorylistCustom(req) {
        return this.http.post(`${config.baseUrl}/claueseCategorylistCustom`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    clauseCategoryTermsRecordsCustom(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryTermsRecordsCustom`, req)
    }

    clauseCategoryRecordsAccordingToCpForm(req) {
        return this.http.post(`${config.baseUrl}/clauseCategoryRecordsAccordingToCpForm`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }


    /* don't know */
    companyadmindelete(req) {
        return this.http.post(`${config.baseUrl}/companyadmindelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    companyadmincreate(req) {
        return this.http.post(`${config.baseUrl}/companyadmincreate`, req, { headers: this.headers });
    }

    companyadminRetrive(req) {
        return this.http.post(`${config.baseUrl}/companyadminRetrive`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    BrokerDelete(req) {
        return this.http.post(`${config.baseUrl}/BrokerDelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    userroleread(req) {
        return this.http.post(`${config.baseUrl}/userroleread`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    removeUser(req) {
        return this.http.post(`${config.baseUrl}/removeUser`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    createasubadmin(req) {
        console.log(req);
        return this.http.post(`${config.baseUrl}/createasubadmin`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    userRemovearchive(req) {
        return this.http.post(`${config.baseUrl}/userRemovearchive`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    cpFormArchivelist(req) {
        return this.http.post(`${config.baseUrl}/cpFormArchivelist`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    cpFormdeleteArchive(req) {
        return this.http.post(`${config.baseUrl}/cpFormdeleteArchive`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    claueseCategoryArchivelist() {
        return this.http.get(`${config.baseUrl}/claueseCategoryArchivelist`,
            { headers: this.headers });
    }

    claueseDetaiarchivellist() {
        return this.http.get(`${config.baseUrl}/claueseDetaiarchivellist`,
            { headers: this.headers });
    }

    claueseDetailarchivedelete(req) {
        return this.http.post(`${config.baseUrl}/claueseDetailarchivedelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    claueseCategoryArchivedelete(req) {
        return this.http.post(`${config.baseUrl}/claueseCategoryArchivedelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    newsAdd(req) {
        return this.http.post(`${config.baseUrl}/newsAdd`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    newsDelete(req) {
        return this.http.post(`${config.baseUrl}/newsDelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    newsArchiveDelete(req) {
        return this.http.post(`${config.baseUrl}/newsArchiveDelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    newsList() {
        return this.http.get(`${config.baseUrl}/newsList`,
            { headers: this.headers });
    }

    newsarchiveList() {
        return this.http.get(`${config.baseUrl}/newsarchiveList`,
            { headers: this.headers });
    }

    newsEdit(req) {
        return this.http.post(`${config.baseUrl}/newsEdit`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    subcategoryEdit(req) {
        return this.http.post(`${config.baseUrl}/subcategoryEdit`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    categoryAdd(req) {
        return this.http.post(`${config.baseUrl}/categoryAdd`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    categoryDelete(req) {
        return this.http.post(`${config.baseUrl}/categoryDelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    subcategoryDelete(req) {
        return this.http.post(`${config.baseUrl}/subcategoryDelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    categoryArchiveDelete(req) {
        return this.http.post(`${config.baseUrl}/categoryArchiveDelete`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    BrokerManegeAlerlist() {
        return this.http.get(`${config.baseUrl}/BrokerManegeAlerlist`,
            { headers: this.headers });
    }

    BrokerManegeAlerarchivelist() {
        return this.http.get(`${config.baseUrl}/BrokerManegeAlerarchivelist`,
            { headers: this.headers });
    }

    categoryEdit(req) {
        return this.http.post(`${config.baseUrl}/categoryEdit`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    OwnerRecords() {
        return this.http.get(`${config.baseUrl}/OwnerRecords`,
            { headers: this.headers });
    }

    brokerAssignment(req) {
        return this.http.post(`${config.baseUrl}/BrokerAssignment`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    /* broker company apiModel */

    companyList(req) {
        return this.http.post(`${config.baseUrl}/brokerCompanyList`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getBrokerCompanyAssignments(req) {
        return this.http.post(`${config.baseUrl}/getBrokerCompanyAssignments`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    updateBrokerCompanyAssignment(req) {
        return this.http.post(`${config.baseUrl}/updateBrokerCompanyAssignment`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    allreportchangelist() {
        return this.http.get(`${config.baseUrl}/allreportchangelist`, { headers: this.headers });
    }

    issueStatusUpdate(req) {
        return this.http.post(`${config.baseUrl}/issueStatusUpdate`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getCompanyForUserTracker(req) {
        return this.http.post(`${config.baseUrl}/companyRecordsServerSide`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getUserForUserTracker(req) {
        return this.http.post(`${config.baseUrl}/userRecordsServerSide`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getActivityRecordTracker(req) {
        return this.http.post(`${config.baseUrl}/tableDataActivityRecord`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getUserActivityRecordTracker(req) {
        return this.http.post(`${config.baseUrl}/usertableDataActivityRecord`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    getCountries(req){
        return this.http.post(`${config.baseUrl}/getCountry`,
        { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }
    getStates(req){
        return this.http.post(`${config.baseUrl}/getState`,
        { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }
    getCities(req){
        return this.http.post(`${config.baseUrl}/getCity`,
        { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }

    /** Admin Activity */

    getIPaddress() {
        return this.http.get('https://api.ipify.org/?format=json');
    }

    adminactivity(req){
        return this.http.post(`${config.baseUrl}/adminactivity`,
        { info: this.crptoJS.encryptData(req) },
            { headers: this.headers })
    }

    /** New changes */
    tradingRecordsServerSide(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/tradingRecordsServerSide`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    AdminTradingFormRecordsServerSide() {
        // console.log(req);
        return this.http.get(`${config.baseUrl}/AdminTradingFormRecordsServerSide`,
            { headers: this.headers });
    }

    AdmindrawRecordsServerSide() {
        // console.log(req);
        return this.http.get(`${config.baseUrl}/AdmindrawRecordsServerSide`,
            { headers: this.headers });
    }

    getchartererList_new() {
        return this.http.get(`${config.baseUrl}/AllChartererlistincludecompany`,
            { headers: this.headers });
    }

    getownerList_new() {
        return this.http.get(`${config.baseUrl}/AllOwnerlistincludecompany`,
            { headers: this.headers });
    }

    drawRecordsServerSideSerach(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/drawRecordsServerSideSerach`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }

    tradingRecordsServerSideSerach(req) {
        // console.log(req);
        return this.http.post(`${config.baseUrl}/tradingRecordsServerSideSerach`,
            { info: this.crptoJS.encryptData(req) },
            { headers: this.headers });
    }
}