export let config;
if (window.location.hostname == "localhost") {
    config = {
        // baseUrl: 'http://localhost:3002/api', 
        // imageUrl: 'http://localhost:3002',
        baseUrl: 'https://staging.asbausa.org:3001/api', 
        imageUrl: 'https://staging.asbausa.org:3001',
    }
}
else if (window.location.hostname == "deepblueadmin.xceltec.in") {
    config = {
      baseUrl: 'https://staging.asbausa.org:3001/api', 
      imageUrl: 'https://staging.asbausa.org:3001',
    }
}
else { 
    config = {
        baseUrl: 'https://staging.asbausa.org:3001/api', 
        imageUrl: 'https://staging.asbausa.org:3001',
    } 
}

// export const config = {
//   production: false,
  
//   baseUrl: 'https://staging.asbausa.org:3001/api', 
//   imageUrl: 'https://staging.asbausa.org:3001',

//   /* baseUrl: 'http://localhost:3002/api',
//   imageUrl: 'http://localhost:3002',
//   mainURL: 'https://deepblueadmin.xceltec.in/' */
// }