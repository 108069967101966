import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UserService, AuthenticationService } from 'app/_services';
import { Crypto } from '../../../app/_services';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    public sidebarMenu: any[] = [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: 'network_check',
            url: '/apps/dashboards/analytics',
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapsable',
            icon: 'person_add',
            children: []
        },
        {
            id: 'charterparty',
            title: 'Charter Party',
            type: 'collapsable',
            icon: 'speaker_notes',
            children: []
        },
        {
            id: 'information',
            title: 'Information',
            type: 'collapsable',
            icon: 'info',
            children: []
        },
    ]

    public sidebarSubmenu: any[] = [
        {
            id: 'Users',
            title: 'Company Administrators',
            type: 'item',
            icon: 'business',
            url: '/apps/master/company-admin',
            exactMatch: true
        },
        {
            id: 'Users',
            title: 'Broker List',
            type: 'item',
            icon: 'person_add',
            url: '/apps/broker-management',
            exactMatch: true
        },
        {
            id: 'Users',
            title: 'ASBA Sub Admins',
            type: 'item',
            icon: 'person_add',
            url: '/apps/subadmin-management',
            exactMatch: true
        },
        {
            id: 'Users',
            title: 'Currency Managment',
            type: 'item',
            icon: 'money',
            url: '/apps/currency-managment',
            exactMatch: true
        },
        {
            id: 'Charter Party',
            title: 'CP Form',
            type: 'item',
            icon: 'assignment',
            url: '/apps/cp-form-management',
            exactMatch: true
        },
        {
            id: 'Charter Party',
            title: 'Clause Category',
            type: 'item',
            icon: 'blur_linear',
            url: '/apps/clause-category-management',
            exactMatch: true
        },
        {
            id: 'Charter Party',
            title: 'Clause Terms',
            type: 'item',
            icon: 'ballot',
            url: '/apps/clause-terms-management',
            exactMatch: true
        },
        {
            id: 'Information',
            title: 'Alert Management',
            type: 'item',
            icon: 'perm_scan_wifi',
            url: '/apps/alert-management',
            exactMatch: true
        },
        {
            id: 'Information',
            title: 'News and Notification',
            type: 'item',
            icon: 'add_alert',
            url: '/apps/alert-toall',
            exactMatch: true
        },
        {
            id: 'Information',
            title: 'Vessel Management',
            type: 'item',
            icon: 'directions_boat',
            url: '/apps/vessel-management',
            exactMatch: true
        },
        {
            id: 'Information',
            title: 'Frequently Asked Questions',
            type: 'item',
            icon: 'library_books',
            url: '/apps/faq-management',
            exactMatch: true
        },
        {
            id: 'Information',
            title: 'Platform Terms & Conditions',
            type: 'item',
            icon: 'list_alt',
            url: '/apps/terms-condition',
            exactMatch: true
        },
        // {
        //     id: 'Information',
        //     title: 'Trade Timer',
        //     type: 'item',
        //     icon: 'list_alt',
        //     url: '/apps/trade-timer',
        //     exactMatch: true
        // },
        {
            id: 'Information',
            title: 'Welcome Note',
            type: 'item',
            icon: 'list_alt',
            url: '/apps/calligraphy',
            exactMatch: true
        },
        {
            id: 'Information',
            title: 'User Settings',
            type: 'item',
            icon: 'list_alt',
            url: '/apps/user-setting',
            exactMatch: true
        },
        {
            id: 'Information',
            title: 'DeepBlue Support',
            type: 'item',
            icon: 'list_alt',
            url: '/apps/platform-admin',
            exactMatch: true
        },
        {
            id: 'Information',
            title: 'Report changes',
            type: 'item',
            icon: 'speaker_notes',
            url: '/apps/report-changes',
            exactMatch: true
        }
    ]

    userProvidedMenu: any[] = [];
    userSelectedMenu: any[] = [];
    DBMenuList: any[] = [];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(private auth: AuthenticationService, private cryptoJS: Crypto, private _changeDetectorRef: ChangeDetectorRef, private _fuseNavigationService: FuseNavigationService, private _userservice: UserService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getUserProvidedMenu();
    }

    getUserProvidedMenu(): void {
        try {
            this._userservice.selectList({ id: localStorage.getItem('userId') })
                .subscribe(
                    res => {

                        let tempData: any = res;
                        if (tempData.success === true) {
                            localStorage.setItem('roleaccess', JSON.stringify(tempData.data));
                            tempData.data = this.cryptoJS.decryptData(tempData.data);
                            this.userProvidedMenu = tempData.data;
                        }
                    },
                    err => {
                        console.log(err);
                        if (err.status === 401 || err.status === 403) this.auth.logout();
                    }
                );
            this.getDBMenuList();
        }
        catch (err) { console.log(err); }
    }

    getDBMenuList(): void {
        try {
            this._userservice.roleListshow()
                .subscribe(
                    res => {
                        let tempData: any = res;
                        tempData.data = this.cryptoJS.decryptData(tempData.data)
                        if (tempData.success === true) {
                            this.DBMenuList = tempData.data;
                            this.DBMenuList.forEach(
                                menu => {
                                    menu.action_arr.forEach(
                                        submenu => {
                                            if (this.userProvidedMenu.some(upm => upm.moduleId == submenu.moduleId && upm.actionId == submenu.id)) {
                                                this.userSelectedMenu.push({
                                                    name: menu.moduleName,
                                                    submenu: submenu.actionName
                                                })
                                            }
                                        });
                                });
                        }
                        this.makeSidebarMenu();
                    },
                    err => {
                        console.log(err);
                        if (err.status === 401 || err.status === 403) this.auth.logout();
                    }
                );
        }
        catch (err) { console.log(err); }
    }

    makeSidebarMenu() {
        let attachSidebar: any[] = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        let userRoleID = localStorage.getItem('userRoleId');

    
        if (userRoleID === "8") {
            this.sidebarSubmenu.forEach(
                submenu => {
                    this.sidebarMenu.forEach(
                        menu => {
                            if (menu.title == submenu.id) menu.children.push(submenu);
                        });
                });
            this.sidebarMenu.push({
                id: 'brokerassignment',
                title: 'Assignment',
                type: 'item',
                icon: 'assignment',
                url: '/apps/broker-assignment',
            },{
                title: 'Admin Tracker',
                type: 'item',
                icon: 'schedule',
                url: '/pages/admin-tracker',
            },{
                id: 'userTracker',
                title: 'User Tracker',
                type: 'item',
                icon: 'schedule',
                url: '/pages/user-tracker',
            })
        }
        else if (userRoleID === "1") {
            
            this.sidebarSubmenu.forEach(
                submenu => {
                    this.sidebarMenu.forEach(
                        menu => {
                            if (menu.title == submenu.id) menu.children.push(submenu);
                        });
                });
        }
        else {
            /* when superadmin provide some menu */
            if (this.userSelectedMenu.length > 0) {
                this.sidebarSubmenu.forEach(
                    submenu => {
                        if (this.userSelectedMenu.some(usm => usm.submenu == submenu.title)) {
                            this.sidebarMenu.forEach(
                                menu => {
                                    if (menu.title == submenu.id) menu.children.push(submenu);
                                });
                        }
                    });
            }
            else { /* when user new create */
                this.sidebarMenu = [];

                this.sidebarMenu.push({
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    icon: 'network_check',
                    url: '/apps/dashboards/analytics',
                })
            }
        }

        /* remove empty menu */
        this.sidebarMenu.forEach(
            (menu, mi) => {
                if (menu.children && menu.children.length === 0) {
                    this.sidebarMenu.splice(mi, 1);
                }
            }
        )

        attachSidebar[0].children = this.sidebarMenu;
        // Load the navigation either from the input or from the service
        this.navigation = attachSidebar;

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
        });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
        });
    }
}
